import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import ExternalLink from '../../components/ExternalLink';
import LeadParagraph from '../../components/LeadParagraph';
import InternalLink from '../../components/InternalLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "ภาพรวมระบบ-erpnext",
      "style": {
        "position": "relative"
      }
    }}>{`ภาพรวมระบบ ERPNext`}<a parentName="h1" {...{
        "href": "#%E0%B8%A0%E0%B8%B2%E0%B8%9E%E0%B8%A3%E0%B8%A7%E0%B8%A1%E0%B8%A3%E0%B8%B0%E0%B8%9A%E0%B8%9A-erpnext",
        "aria-label": "ภาพรวมระบบ erpnext permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
   ERP ที่คล่องตัวที่สุดในโลก
    </LeadParagraph>
    <h2 {...{
      "id": "ธุรกิจที่เหมาะกับการนำไปใช้งาน",
      "style": {
        "position": "relative"
      }
    }}>{`ธุรกิจที่เหมาะกับการนำไปใช้งาน`}<a parentName="h2" {...{
        "href": "#%E0%B8%98%E0%B8%B8%E0%B8%A3%E0%B8%81%E0%B8%B4%E0%B8%88%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B9%80%E0%B8%AB%E0%B8%A1%E0%B8%B2%E0%B8%B0%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%99%E0%B8%B3%E0%B9%84%E0%B8%9B%E0%B9%83%E0%B8%8A%E0%B9%89%E0%B8%87%E0%B8%B2%E0%B8%99",
        "aria-label": "ธุรกิจที่เหมาะกับการนำไปใช้งาน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`ธุรกิจด้านบริการ ผู้ผลิตสินค้า ซื้อมาขายไป การจัดจำหน่าย การศึกษามหาวิทยาลัย  โรงแรม การเกษตรกรรม โรงพยาบาล และอื่นๆ`}</p>
    <h3 {...{
      "id": "ค่าใช้จ่ายและการนำไปใช้งาน",
      "style": {
        "position": "relative"
      }
    }}>{`ค่าใช้จ่ายและการนำไปใช้งาน`}<a parentName="h3" {...{
        "href": "#%E0%B8%84%E0%B9%88%E0%B8%B2%E0%B9%83%E0%B8%8A%E0%B9%89%E0%B8%88%E0%B9%88%E0%B8%B2%E0%B8%A2%E0%B9%81%E0%B8%A5%E0%B8%B0%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%99%E0%B8%B3%E0%B9%84%E0%B8%9B%E0%B9%83%E0%B8%8A%E0%B9%89%E0%B8%87%E0%B8%B2%E0%B8%99",
        "aria-label": "ค่าใช้จ่ายและการนำไปใช้งาน permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`ไลเซนต์อนุญาตแบบ open source สามารถนำไปใช้งานได้อย่างเสรีไม่มีข้อจำกัดหรือผูกมัด เพียงแต่ต้องใช้งานภายใต้ลิขสิทธ์แบบ open source ซึ่ง ERPNext เลือกใช้ไลเซนต์ชนิด GPL version 3`}</p>
    <h3 {...{
      "id": "ข้อมูลเพิ่มเติมสำหรับนักพัฒนา",
      "style": {
        "position": "relative"
      }
    }}>{`ข้อมูลเพิ่มเติมสำหรับนักพัฒนา`}<a parentName="h3" {...{
        "href": "#%E0%B8%82%E0%B9%89%E0%B8%AD%E0%B8%A1%E0%B8%B9%E0%B8%A5%E0%B9%80%E0%B8%9E%E0%B8%B4%E0%B9%88%E0%B8%A1%E0%B9%80%E0%B8%95%E0%B8%B4%E0%B8%A1%E0%B8%AA%E0%B8%B3%E0%B8%AB%E0%B8%A3%E0%B8%B1%E0%B8%9A%E0%B8%99%E0%B8%B1%E0%B8%81%E0%B8%9E%E0%B8%B1%E0%B8%92%E0%B8%99%E0%B8%B2",
        "aria-label": "ข้อมูลเพิ่มเติมสำหรับนักพัฒนา permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>สามารถดูข้อมูลเพิ่มเติมได้จากเว็ปไซต์ <ExternalLink href="https://erpnext.com/" mdxType="ExternalLink">ERPNext</ExternalLink>.</p>
    <h3 {...{
      "id": "หรือดาวน์โหลด-source-code-ได้ที่",
      "style": {
        "position": "relative"
      }
    }}>{`หรือดาวน์โหลด source code ได้ที่`}<a parentName="h3" {...{
        "href": "#%E0%B8%AB%E0%B8%A3%E0%B8%B7%E0%B8%AD%E0%B8%94%E0%B8%B2%E0%B8%A7%E0%B8%99%E0%B9%8C%E0%B9%82%E0%B8%AB%E0%B8%A5%E0%B8%94-source-code-%E0%B9%84%E0%B8%94%E0%B9%89%E0%B8%97%E0%B8%B5%E0%B9%88",
        "aria-label": "หรือดาวน์โหลด source code ได้ที่ permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>GitHub <ExternalLink href="https://github.com/frappe/erpnext" mdxType="ExternalLink">ERPNext in GitHub</ExternalLink></p>
    <h3 {...{
      "id": "หรือต้องการปรึกษาเรา",
      "style": {
        "position": "relative"
      }
    }}>{`หรือต้องการปรึกษาเรา`}<a parentName="h3" {...{
        "href": "#%E0%B8%AB%E0%B8%A3%E0%B8%B7%E0%B8%AD%E0%B8%95%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%9B%E0%B8%A3%E0%B8%B6%E0%B8%81%E0%B8%A9%E0%B8%B2%E0%B9%80%E0%B8%A3%E0%B8%B2",
        "aria-label": "หรือต้องการปรึกษาเรา permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`ปรึกษาฟรี `}<InternalLink href="https://anndream.com/contact" mdxType="InternalLink">{` ERP Consult`}</InternalLink></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      